import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import SEO from '../components/seo';
import Button from '../components/Button';
import {
  TeaserBox,
  TeaserRow,
  TeaserSizer,
  BlogTeaserContent,
} from '../components/Teaser';

function BlogIndex(props) {
  const { data } = props;
  const { node: blogPost } = data.allMarkdownRemark.edges[0];

  const homepageHero = () => (
    <Hero>
      <h1>I love creating wonderful things on the web! 🚀</h1>
      <h2>
        Do you share my love, have questions or something that needs to be
        build?
      </h2>
      <Link to="/contact">
        <Button>Get in touch 📫</Button>
      </Link>
    </Hero>
  );

  return (
    <Layout hero={homepageHero}>
      <SEO
        title="Homepage"
        keywords={[
          `blog`,
          `gatsby`,
          `javascript`,
          `react`,
          `kanban`,
          `yokanban`,
          `agile`,
        ]}
      />
      <TeaserRow>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <TeaserSizer size={2 / 3}>
            <TeaserBox bottomLinkText="📓 More blogs" bottomLinkTo="/blog">
              <BlogTeaserContent blogPost={blogPost} />
            </TeaserBox>
          </TeaserSizer>
        </div>
      </TeaserRow>
    </Layout>
  );
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 1
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
